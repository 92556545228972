import { useState, useEffect } from "react";
import Logo from "../components/Logo";
import SectionHeader from "../components/SectionHeader";
import Button from "../components/Button";

import "../css/form.css";

export default function CreateAccount() {

    const [firstName, setFirstName ] = useState("");
    const handleSubmit = async (e) => {
        e.preventDefault();
    }

    return (
        <div className="form-container">
            <div className="logo-center">
                <Logo />
            </div>

            <form onSubmit={handleSubmit} className="form">
                <div className="form-title">
                        <SectionHeader title="Create your account" />
                </div>

                <label htmlFor="firstName" className='form-label'>First Name:</label>
                <input id="firstName" required/>

                <label htmlFor="firstName" className='form-label'>Last Name:</label>
                <input id="lastName" required/>

                <label htmlFor="password" className='form-label'>Password:</label>
                <input type="password" id="password" required />

                <label htmlFor="retypePassword" className='form-label'>Re-type your Password:</label>
                <input type="password" id="password" required />

                <div className="form-btn">
                    <Button
                        type="button"
                        size="full"
                        bgColor="green"
                        isSubmit="true"
                    >
                        Create Account
                    </Button> 
                </div>
            </form>
        </div>
    )
}