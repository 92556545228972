import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import Logo from "../components/Logo";
import SectionHeader from "../components/SectionHeader";
import Button from "../components/Button";

import "../css/form.css";


export default function SigninForm() {
    const [error, setError] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        //Validation
        // if (!email) {
        //     setError('Please type your email.');
        //     return;
        // }

        // if (!password) {
        //     setError('Please type your password.');
        //     return;
        // }
    }

    return (
        <div className="form-container">
            <div className="logo-center">
                <Logo />
            </div>

            <form onSubmit={handleSubmit} className="form">

                <div className="form-title">
                        <SectionHeader title="Sign In" />
                </div>

                <label htmlFor="email" className='form-label'>Email:</label>
                    <input
                        type="email"
                        id="email"
                        required
                />
                <label htmlFor="password" className='form-label'>Password:</label>
                    <input
                        type="password"
                        id="password"
                        required
                />
                
                <div className="form-btn">
                    <Button
                        type="button"
                        size="full"
                        bgColor="green"
                        isSubmit="true"
                    >
                        Sign in
                    </Button> 
                </div>

            </form>

            <div className="form-btn">
                <div className="form-title">
                    <SectionHeader subTitle="New to Vegan Mundi?" />
                </div>

                <Link to="/create-account">
                    <Button
                        type="button"
                        size="full"                        
                    >
                        Create Account
                    </Button>
                </Link>
            </div>

        </div>
    )
}