import { useEffect } from "react";
import config from '../utils/config.js';

export function useAddPreSignedUrlToArray(dependencyArray, s3BucketName, setImages, setIsLoading) {

    useEffect( ()=> {
        console.log(`dependencyArray.length: ${dependencyArray.length}`);
        
        if (dependencyArray.length > 0) {
            addPreSignedUrlToArray(dependencyArray, s3BucketName, setImages, setIsLoading);
        } else
        {
            setIsLoading(false);
            setImages([]);
        }
    }, dependencyArray)

    // Add to any array of Images the pre-signed URL required from AWS for private S3Bucket objects to be accessed
    async function addPreSignedUrlToArray(sectionData, bucket, setImages, setIsLoading) {
        console.log("Calling addPreSignedUrlToArray...");

        const s3Objects = await Promise.all(
            sectionData.map(async (item) => {
                const photo = item.PHOTO;
                console.log(`photo: ${photo}`);

                try {
                    const response = await fetch(`${config.serverEndpoint}s3/${bucket}/${photo}`);
                    const preSignedUrl = await response.json();

                    console.log(`preSignedUrl: ${preSignedUrl}`);
    
                    return { ...item, PRE_SIGNED_URL: preSignedUrl}
                }
                catch (err) {
                    console.log(`Couldn't fetch the item image: ${photo}`)
                }
            })
        )
        setImages(s3Objects);
        setIsLoading(false);
    }

}